import Headroom from 'headroom.js';
import {Popover, ScrollSpy} from "bootstrap";

import {INIT_FANCYBOX} from "./js/components/init-fancybox";
import {INIT_COOKIE_MANAGER} from "./js/ccm/init.ccm";
import {INIT_SCROLL_TOP} from "./js/components/init-scroll-top";
import {INIT_SCROLLSPY_ANCHOR_NAV, INIT_SCROLLSPY_RECRUITING_NAV} from "./js/components/init-scrollspy";
import {INIT_ANCHOR_NAV} from "./js/components/init-anchor-nav";
import {INIT_HEADROOM} from "./js/components/init-headroom";
import {INIT_SEARCH_HEADER} from "./js/components/init-search-header";
import {INIT_POPOVER} from "./js/components/init-popover";
import {INIT_SWIPER} from "./js/components/init-swipers";
import {INIT_SMOOTH_SCROLL} from "./js/components/init-smooth-scroll";
import {INIT_NAV_DROPDOWN} from "./js/components/init-nav-dropdown";
import {INIT_MOBILE_NAV_HANDLER} from "./js/components/init-mobile-nav-handler";

document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        document.documentElement.classList.replace('no-js', 'js-rules');
        INIT_SCROLLSPY_ANCHOR_NAV(ScrollSpy);
        INIT_SCROLLSPY_RECRUITING_NAV(ScrollSpy);
    }, 90);

    INIT_NAV_DROPDOWN();
    INIT_MOBILE_NAV_HANDLER();

    INIT_FANCYBOX();
    INIT_COOKIE_MANAGER();
    INIT_POPOVER(Popover);
    INIT_SCROLL_TOP();
    INIT_ANCHOR_NAV();
    INIT_SMOOTH_SCROLL();
    INIT_HEADROOM(Headroom);
    INIT_SEARCH_HEADER();
    INIT_SWIPER()
});
