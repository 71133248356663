export function INIT_NAV_DROPDOWN() {
    let mql = window.matchMedia("(min-width: 1200px)");

    const NAV_DROP_DOWNS = document.querySelectorAll('.navbar-mobile .nav-item > .dropdown-link, .navbar-desktop .nav-item > .dropdown-link');


    function ACTIVATE_DROPDOWN(DROP_DOWN) {
        const DROP_DOWN_NAV = DROP_DOWN.querySelector('.dropdown-menu');
        const DROP_DOWN_NAV_HEIGHT = DROP_DOWN_NAV.getBoundingClientRect().height;

        DROP_DOWN.classList.add('visible')
        DROP_DOWN.style.setProperty('--dropdown-height', `${DROP_DOWN_NAV_HEIGHT}px`);
    }

    function DEACTIVATE_DROPDOWN(DROP_DOWN) {
        DROP_DOWN.classList.remove('visible')
        DROP_DOWN.style.removeProperty('--dropdown-height');
    }

    function HANDLE_CLICK(DROP_DOWN) {
        DROP_DOWN.addEventListener('click', event => {
            const DROPDOWN_MENU_ELEMENT = DROP_DOWN.querySelector('.dropdown-menu');

            if (DROP_DOWN.classList.contains('visible')) {
                if (event.composedPath().includes(DROPDOWN_MENU_ELEMENT)) return;
                DEACTIVATE_DROPDOWN(DROP_DOWN);
            } else ACTIVATE_DROPDOWN(DROP_DOWN);

            const SIBLING_DROPDOWNS = Array.from(NAV_DROP_DOWNS).filter(MOBILE_DROP_DOWN => MOBILE_DROP_DOWN !== DROP_DOWN);
            SIBLING_DROPDOWNS.forEach(SIBLING_DROPDOWN => DEACTIVATE_DROPDOWN(SIBLING_DROPDOWN));

        });
    }

    function HANDLE_MOUSE_ENTER(DROP_DOWN) {
        DROP_DOWN.addEventListener('mouseenter', () => {
            if (mql.matches && !DROP_DOWN.classList.contains('visible')) ACTIVATE_DROPDOWN(DROP_DOWN);
        })
    }

    function HANDLE_MOUSE_LEAVE(DROP_DOWN) {
        DROP_DOWN.addEventListener('mouseleave', () => {
            if (mql.matches && DROP_DOWN.classList.contains('visible')) DEACTIVATE_DROPDOWN(DROP_DOWN)
        })
    }

    NAV_DROP_DOWNS.forEach(DROP_DOWN => {
        HANDLE_CLICK(DROP_DOWN);
        HANDLE_MOUSE_ENTER(DROP_DOWN);
        HANDLE_MOUSE_LEAVE(DROP_DOWN);
    })


}
